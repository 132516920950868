#dashboard-container {
  .print-only {
    display: none;
  }
}

.dashboard-header {
  display: flex;
  justify-content: space-between;

  h5 {
    font-size: 24px;
    font-weight: 600;
  }
}

.dashboard-filter {
  padding-left: 15px;

  .filter-title {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 14px;
    font-weight: 600;
  }

  .filter-btn-group {
    margin: 5px;

    .filter-btn {
      margin-right: 10px;
    }
  }
}

.chart-header-row {
  display: flex;
  justify-content: space-between;
}

.chart-title {
  font-size: 16px;
  font-weight: bold;
}

.chart-title-light {
  font-size: 14px;
  font-weight: normal;
}

.dashboard-btn {
  border: 1px solid #c6c6c6;
  background: #ffffff;
  color: #393939;
}

.dashboard-btn.clicked {
  background-color: #007aff;
  color: white;
  border: 1px solid #c6c6c6;
}

// .dashboard-btn:focus {
//   background-color: #007aff;
//   color: white;
//   border: 1px solid #c6c6c6;
// }
.dashboard-btn:hover {
  background-color: #007aff;
}

li {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.percent-bank {
  font-size: 12px;
  font-weight: 600;
  width: 20%;
  text-align: right;
}

.total-amount-bank {
  font-size: 12px;
  font-weight: 600;
  width: 44%;
  text-align: right;
}

.KBANK {
  color: #21d59b;
}

.SCB {
  color: #c28dff;
}

.BAY {
  color: #ffc700;
}

.KTB {
  color: #57b8ff;
}

.KTC {
  color: #57b8ff;
}

.BBL {
  color: #1155cc;
}

.TTB, .TMB {
  color: #007aff;
}

.UOB {
  color: #7e84a3;
}

.CTB {
  color: #e20a1a;
}

.GSB {
  color: #ff8dc6;
}

.table-header-chart {
  font-size: 14px;
  font-weight: 600;
  color: #7e84a3;
}

.grey-text-dashboard {
  font-size: 14px;
  font-weight: 600;
  color: #6f6f6f;
}

.light-grey-text-dashboard {
  color: #525252;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  left: 1.5rem;
  bottom: 3px;
  
  &.textInterval {
    width: 100%;
    text-align: right;
    padding-right: 2.5rem;
  }
}

.avg-dashboard-container {
  min-height: 110px;
}

.avg-num-dashboard {
  font-size: 28px;
  font-weight: 600;
  color: #161616;
}

.border-grey {
  border-color: #e0e0e0;
}

.avg-db-col {
  padding-left: 50px;
}

.percent-num {
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
  align-self: center;
  text-align: right;
}

.green-text {
  color: #07b53b;
}

.red-text {
  color: #e20a1a;
}

#invisible-chart {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.card-bank-legend{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .card-bank-list{
    display: flex;
    align-items: flex-start;
    width: 36%;
  }
}
@media print {
  @page {
    size: A4 !important;
    margin: 20px !important;
    padding: 0 !important;
  }

  .no-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  .page-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  #invisible-chart {
    height: auto;
    margin-bottom: -2.5rem;
    overflow: auto;
    visibility: visible;
  }


}