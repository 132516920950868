

.historylog-wrapper {
  .title-logs {
    color: $navy-blue;
  }
  td {
    font-size: 12px;
    word-break: break-word;

    .action-wrapper {
      width: 52px;
      padding: 3px 4px;
      border-radius: 4px;
      border: 1px solid $green-leaf;
      text-transform: uppercase;
      text-align: center;
      color: $black;
      font-size: 10px;
      font-weight: bold;
      line-height: 1.2;

      &.add, &.create {
        background-color: rgba(7, 181, 59, 0.12);
        border-color: $green-leaf;
      }
      &.update {
        background-color: rgba(255, 214, 10, 0.24);
        border-color: $orange;
      }
      &.delete {
        background-color: rgba(226, 10, 26, 0.08);
        border-color: $fire-brick;
      }
    }
    &.keys {
      text-transform: uppercase;
    }
  }
}