@import './variables';
.filer-section {
  display: flex;
  justify-content: flex-end;
  .nexter-select {
    width: 200px;
    margin-right: 5px;
  }
  .react-datepicker-wrapper {
    margin-right: 5px;
  }
  .nexter-btn {
    margin-right: 5px;
    .no-content {
      margin: 0 !important;
    }
  }
  .nexter-btn.button-datepicker.btn.btn-outline-default.btn-md {
    height: auto;
  }
}
.title {
  font-size: 20px;
  font-weight: 600;
  color: #161616;
  margin: 0;
}
.btn-back {
  color: $SCG-red;
  background-color: transparent;
  border: none;
  margin: 0 16px;
}
.no-btn-default {
  background-color: transparent;
  border: none;
}
.as-link {
  color: #007aff;
  &:hover {
    cursor: pointer;
  }
}

.nav-tabs{
  border-bottom: none;
  .nav-item {
    text-align: center;
    .nav-link {
      color: #6f6f6f;
      background-color: #e0e0e0;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      &:hover {
        color: #161616;
      }
      &.active {
        background-color: #ffffff;
        color: #161616;
        border-color: white;
        border-top: 2px solid $SCG-red;
        font-weight: 700;
      }
    }
  }
}
.tab-content {
  .with-card {
    min-height: 500px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    padding: 1em;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
.nav-bar-bottom-line {
  display: flex;
  border-bottom: 1.5px solid #c6c6c6;
  .nav-tabs .nav-link.active {
    background-color: #ffffff;
    color: $SCG-red;
    border-color: white;
    border-bottom: 2px solid $SCG-red;
    font-weight: 700;
  }
  .nav-item .nav-link {
    color: #6f6f6f;
    background-color: #ffffff;
    border-radius: 0;
    font-size: 16px;
    font-weight: 600;
  }
  .nav-item:hover {
    border: none;
  }
  .nav-item:focus {
    border: none;
  }
  .nav-link:hover {
    border: none;
  }
  .nav-link:focus {
    border: none;
  }
}
.transfer-table {
  .custom-reacttable {
    height: 100vh;
  }
}
