$trigger-size: 20px;
$footer-height: 115px;

#sidebar-container {
  width: 250px;
  position: relative;
  background-color: $white;
  border-right: 1px solid $light-silver;
  transition: 0.3s;
  #menu-container {
    height: calc(100vh - #{$navbar-height} - #{$footer-height});
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 0;
    color: $dim-gray;
    transition: 0.3s;
    .menu-group {
      width: 250px;
      overflow: hidden;
      margin-top: 0.5rem;
      margin-bottom: 0.75rem;
      padding-left: 1.5rem;
      padding-right: 1rem;
      color: inherit;
      font-size: 14px;
      white-space: nowrap;
      text-transform: uppercase;
    }
    .menu-item {
      width: 100%;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-left: 1.5rem;
      padding-right: 1rem;
      color: inherit;
      .menu-link {
        display: inline-block;
        width: 250px;
        overflow: hidden;
        color: inherit;
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;
        transition: 0.3s;
        &.active,
        &:hover {
          color: $SCG-red;
        }
        .menu-icon {
          width: 24px;
          margin-right: 12px;
          font-size: 18px;
          vertical-align: super;
          text-align: center;
          color: inherit;
        }
        .menu-label {
          margin: 0;
          color: inherit;
          vertical-align: 6px;
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    .menu-divider {
      width: 100%;
      height: 1px;
      margin: 0.5rem 1.5rem;
      border-top: 1px solid $silver;
    }
  }
  &.sidebar-collapse {
    width: 20px;
    #menu-container {
      overflow: hidden;
      opacity: 0;
    }
    #footer-container {
      opacity: 0;
    }
  }
}

#footer-container {
  padding: 1.5rem 1rem;
  font-size: 10px;
  color: $gray;
  background-color: $white;
  transition: 0.15s;
  max-width: 100%;
  opacity: 1;
  overflow: hidden;
  p {
    width: calc(250px - 2rem);
  }
}

.trigger {
  width: $trigger-size;
  height: $trigger-size;
  position: absolute;
  top: 50px;
  right: -10px;
  z-index: 5;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  font-size: calc($trigger-size / 2);
  text-align: center;
  line-height: $trigger-size;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    color: $white;
    background-color: $SCG-red;
  }
  i.nc-icon {
    font-weight: bold;
    vertical-align: middle;
  }
}
