@media not print {
  .table-scroll {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: auto;
    max-height: 68vh;
  }

  .table-scroll table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .table-scroll tfoot,
  .table-scroll tfoot th,
  .table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 0;
    border-top: 1px solid #dee2e6;
  }

  .table-scroll thead,
  .table-scroll thead th,
  .table-scroll thead td {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
