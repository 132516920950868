.input-group.input-group-xs {
  height: fit-content;
}

.input-group-xs > .form-control:not(textarea),
.input-group-xs > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-xs > .form-control,
.input-group-xs > .custom-select,
.input-group-xs > .input-group-prepend > .input-group-text,
.input-group-xs > .input-group-append > .input-group-text,
.input-group-xs > .input-group-prepend > .btn,
.input-group-xs > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.575rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
