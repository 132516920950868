@media screen and (max-width: 1991px) {
  .navbar {
    padding: 20;

    &.navbar-absolute {
      padding-top: 0;
    }

    .navbar-brand {
      font-size: 16px;
      margin-right: 10;
    }
  }

  .profile-photo .profile-photo-small {
    margin-left: -2px;
  }

  .button-dropdown {
    display: none;
  }

  #minimizeSidebar {
    display: none;
  }

  .navbar {
    .container-fluid {
      padding-right: 35px;
      padding-left: 0px;
    }

    .navbar-collapse {
      .input-group {
        margin: 0;
        margin-top: 5px;
      }
    }

    .navbar-nav {
      .nav-item:first-child {
        margin-top: 0px;
      }
      .nav-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      width: auto;
      margin: 0px 1rem;
      margin-top: 0px;
      box-shadow: none;
      position: static;
      padding-left: 0px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white-color;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default-color;
    }

    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: $default-color;

      & + .navbar-toggler-bar {
        margin-top: 7px;
      }

      & + .navbar-toggler-bar.navbar-kebab {
        margin-top: 3px;
      }

      &.bar2 {
        width: 17px;
        transition: width 0.2s linear;
      }
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default-color;
    }

    & .toggled .navbar-toggler-bar {
      width: 24px;

      & + .navbar-toggler-bar {
        margin-top: 5px;
      }
    }
  }

  .wrapper {
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .menu-on-right {
    .sidebar {
      overflow-x: hidden;
      overflow-y: hidden;
      left: auto;
      right: 0;
      @include transform-translate-x(240px);
    }
  }

  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }
  .bar1 {
    top: 0px;
    @include bar-animation($topbar-back);
  }
  .bar2 {
    opacity: 1;
  }
  .bar3 {
    bottom: 0px;
    @include bar-animation($bottombar-back);
  }
  .toggled .bar1 {
    top: 6px;
    @include bar-animation($topbar-x);
  }
  .toggled .bar2 {
    opacity: 0;
  }
  .toggled .bar3 {
    bottom: 6px;
    @include bar-animation($bottombar-x);
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 240px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .footer {
    .copyright {
      text-align: right;
    }
  }

  .section-nucleo-icons .icons-container {
    margin-top: 65px;
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.fal,
      i.fas,
      i.fab,
      i.far,
      i.nc-icon {
        opacity: 0.5;
      }
    }
  }

  .main-panel {
    width: 100%;
  }

  .timeline {
    &:before {
      left: 5% !important;
    }

    > li > .timeline-badge {
      left: 5% !important;
    }

    > li > .timeline-panel {
      float: right !important;
      width: 82% !important;

      &:before {
        border-left-width: 0 !important;
        border-right-width: 15px !important;
        left: -15px !important;
        right: auto !important;
      }

      &:after {
        border-left-width: 0 !important;
        border-right-width: 14px !important;
        left: -14px !important;
        right: auto !important;
      }
    }
  }
  .nav-open {
    .main-panel {
      right: 0;
      @include transform-translate-x(0px);
      width: 100%;
    }

    .sidebar {
      @include transform-translate-x(0px);
    }

    body {
      position: auto;
      overflow-x: auto;
    }
  }
  .sidebar,
  .bootstrap-navbar {
    position: auto;
    display: block;
    top: 0;
    height: 100%;
    width: 240px;
    right: auto;
    left: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 0;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    @include transform-translate-x(-240px);
  }

  .nav-open {
    .main-panel {
      right: -20px;
      @include transform-translate-x(0px);
      width: calc(103% - 240px);
    }

    .sidebar {
      @include transform-translate-x(0px);
    }

    body {
      position: auto;
      overflow-x: hidden;
    }
  }
  .sidebar,
  .timeline {
    &:before {
      left: 5% !important;
    }

    > li > .timeline-badge {
      left: 5% !important;
    }

    > li > .timeline-panel {
      float: right !important;
      width: 82% !important;

      &:before {
        border-left-width: 0 !important;
        border-right-width: 15px !important;
        left: -15px !important;
        right: auto !important;
      }

      &:after {
        border-left-width: 0 !important;
        border-right-width: 14px !important;
        left: -14px !important;
        right: auto !important;
      }
    }
  }
}
