@import './InstallmentPage.scss';
@import './PromotionPage.scss';
@import './VoidPage.scss';
@import './systemConfig.scss';

.system-config-title {
  margin-top: 15px;
  color: #0f50c1;
  font-size: 20px;
  font-weight: bold;
}

.bg-config {
  background-color: #f4f7fc !important;
}

.config-header {
  padding: 12px 15px !important;
  font-weight: bold;
  font-size: 16px;
}

.no-r-tl {
  border-top-left-radius: 0;
}
