.blue-smoke {
  background: $blue-smoke;
}
.role-permission-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  margin: 2rem 1rem;
  letter-spacing: normal;
  text-align: left;
  color: #0f50c1;
}
.menu-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  margin: 0rem 1rem;
  letter-spacing: normal;
  text-align: left;
  color: #0f50c1;
  margin-top: revert;
}

.grey-white {
  width: 184px;
  height: 132px;
  margin: 10px 0 0;
  background-color: #6f6f6f;
  color: white;
  font-size: 14px;
}
.grey-black {
  width: 480px;
  height: 66px;
  margin: 10px 0 0;
  padding: 24px 6px;
  background-color: #c6c6c6;
  font-size: 14px;
}
.light-grey-black {
  width: 120px;
  height: 66px;
  margin: 10px 0 0;
  background-color: #e0e0e0;
  font-size: 14px;
}
.smoke-white-black {
  width: 120px;
  height: 132px;
  margin: 10px 0 0;
  padding: 33px 0;
  background-color: #f4f4f4;
  font-size: 14px;
  border: solid 1px #c6c6c6;
}
.grey-border {
  border: solid 1px #c6c6c6;
}
.grey-border-right {
  border-right: solid 1px #c6c6c6;
}
.light-grey-border {
  border: solid 1px #c6c6c6;
}
.table-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #0f50c1;
}
.table-margin {
  margin-top: 20px;
}
.table-header {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
