//Sweet Alert
.swal2-container {
    .swal2-popup {
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(22, 22, 22, 0.5);
        background-color: $color-white;
        padding: 36px;
    }
    .nexter-alert {
        .swal2-icon.swal2-error {
            font-size: 10px;
            border: 0.6em solid #e20a1a;
            margin: 0 0 20px 0;
        }
        .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
            height: 7px;
            background-color: #e20a1a;
        }
        .swal2-icon.swal2-success{
            font-size: 10px;
            border: 0em solid #0eb70e;
            border-color: #0eb70e;
            margin: 10px 0 20px 0;
        }
        .swal2-icon.swal2-success .swal2-success-ring {
            border: 0.6em solid #0eb70e;
        }
        .swal2-icon.swal2-success [class^=swal2-success-line] {
            height: 7px;
            background-color: #0eb70e;
        }
        .swal2-icon.swal2-warning {
            font-size: 10px;
            font-weight: bold;
            color: #fba422;
            border: 0.6em solid #fba422;
            margin: 0px 0 20px 0;
        }
        .swal2-icon.swal2-info {
            font-size: 10px;
            font-weight: bold;
            color: #1155cc;
            border: 0.6em solid #1155cc;
            margin: 0px 0 20px 0;
        }
        .swal2-icon.swal2-question {
            font-size: 10px;
            font-weight: bold;
            color: #8d8d8d !important;
            border: 0.6em solid #8d8d8d !important;
            margin: 0px 0 20px 0;
        }
        .swal2-cancel {
            width: 186px;
            height: $size-height-input !important;
            font-size: 18px;
            font-weight: bold;
            color: #e20a1a;
            background-color: #ffffff;
            border: solid 1px #e20a1a;
            border-radius: 8px;
            box-shadow: none;
            &:hover {
                color: #af0815;
                background-color: rgba(226, 10, 26, 0.05);
            }
        }
        .swal2-title {
            font-size: 28px;
            font-weight: 600;
            line-height: 1.29;
            text-align: center;
            color: $color-black;
            margin-bottom: 12px;
        }
        .swal2-content {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.8;
            color: #525252;
            margin-top: 10px;
            margin-bottom: 36px;
        }
        .swal2-actions {
            margin-top: 0px;
        }
        .swal2-actions:not(.swal2-loading) .swal2-confirm {
            width: 186px;
            height: $size-height-input !important;
            background-color: #e20a1a;
            font-size: 18px !important;
            font-weight: 600 !important;
            line-height: 1.33 !important;
            color: $color-white;
            border-radius: 8px;
            box-shadow: none !important;
            border: none !important;
            &:hover {
                background-color: #af0815 !important;
            }
            &:focus {
                border: solid 2px #e20a1a !important;
            }
        }
        .swal2-footer {
            border-top: none;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            text-align: center;
            color: #525252;
        }
        .swal2-textarea {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            color: #8d8d8d;
            box-shadow: none;
            border-radius: 4px;
            border: solid 1px #8d8d8d;
        }
    }
    .nexter-alert-user-manage {
        .swal2-title {
            font-size: 24px;
            margin-top: 6px;
        }
        .swal2-content {
            font-size: 16px;
        }
    }
    .nexter-alert.nexter-alert-channel-manage {
        .swal2-actions.swal2-loading .swal2-confirm {
            border-color: $dark-gray;
        }
        .swal2-actions:not(.swal2-loading) .swal2-confirm {
            width: 186px;
            height: $size-height-input !important;
            color: $dark;
            background-color: $white;
            border-radius: 8px;
            box-shadow: none !important;
            border: 1px solid $dark-gray !important;
            &.active,
            &:hover,
            &:focus {
              color: $dark !important;
              background-color: $gray !important;
              border-color: $dark-gray !important;
            }
            &.active,
            &:focus,
            &:active {
              border-color: $dark !important;
            }
            font-size: 18px !important;
            font-weight: 600 !important;
            line-height: 1.33 !important;
        }
        .swal2-cancel {
            width: 186px;
            height: $size-height-input !important;
            font-size: 18px;
            font-weight: bold;
            color: $white;
            background-color: $SCG-red;
            border-color: $SCG-red;
            &.active,
            &:hover,
            &:focus {
              background-color: $fire-brick !important;
              border-color: $fire-brick;
            }
            &.active,
            &:focus,
            &:active {
              border-color: $SCG-red !important;
            }
        }
        .swal2-title {
            font-size: 26px;
        }
    }
}