// Positioning
// Absolute Centering
@mixin absolutecenter($axis: 'both') {
  position: absolute;

  @if $axis== 'y' {
    top: 50%;
    transform: translateY(-50%);
  }

  @if $axis== 'x' {
    left: 50%;
    transform: translateX(-50%);
  }

  @if $axis== 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Offset
@mixin position($position, $args: ()) {
  $offsets: top right bottom left;
  position: $position;

  @each $offset in $offsets {
    $index: index($args, $offset);

    @if $index {
      @if $index==length($args) {
        #{$offset}: 0;
      } @else {
        $next: nth($args, $index + 1);

        @if is-valid-length($next) {
          #{$offset}: $next;
        } @else if index($offsets, $next) {
          #{$offset}: 0;
        } @else {
          @warn "Invalid value `#{$next}` for offset `#{$offset}`.";
        }
      }
    }
  }
}

@function is-valid-length($value) {
  @return (type-of($value) == 'number' and not unitless($value)) or (index(auto initial inherit 0, $value) !=false);
}

@mixin absolute($args: ()) {
  @include position(absolute, $args);
}

@mixin fixed($args: ()) {
  @include position(fixed, $args);
}

@mixin relative($args: ()) {
  @include position(relative, $args);
}

@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
      @content;
  }
}