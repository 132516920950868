.system-container {
  padding-right: 1rem;
  padding-left: 1rem;
  h4 {
    font-weight: 900;
  }
  .nav-bar-system {
    border-bottom: solid 1px #a8a8a8;
  }
  .tabs-card {
    min-height: 675px;

    padding-bottom: 20px;
    /*
    border-radius: 2px;
    box-shadow: 0 3px 6px 0 rgba(72, 38, 8, 0.16);
    */
    background-color: transparent;
    margin-bottom: 5px;

    .nav-bar-system {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .tab-text {
        text-align: left;
        padding: 10px 30px;
        margin-top: 20px;

        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: normal;
        color: #161616;
        cursor: pointer;
        &:hover {
          color: $SCG-red;
        }
      }
      .tab-click {
        color: #e20a1a;

        transition: width 0.5s ease, background-color 0.5s ease;
        transition: width 0.3s;
        border-bottom: 2px solid $SCG-red;
      }
    }

    .nav-bar-setting.nav {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      /*
      box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
      */

      .nav-item {
        min-width: 108px;

        .tab-text {
          display: block;
          padding: 10px 18px 13px;
          width: 100%;
          height: 100%;
          text-align: center;
          background-color: #e0e0e0;
          border-top: 3px solid #e0e0e0;

          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          color: #9f9f9f;

          letter-spacing: normal;

          cursor: pointer;
          &:hover {
            /* color: $SCG-red; */
            color: #161616;
          }
        }
        .tab-click {
          color: #161616;
          background-color: #fff;
          transition: width 0.5s ease, background-color 0.5s ease;
          transition: width 0.3s;
          border-top: 3px solid $SCG-red;
        }
      }
    }
  }
}
