.ant-select-selector {
  height: 36px;
}
.ant-select-dropdown {
  border-radius: 8px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-radius: 8px;
  border-color: #8d8d8d;
}

.ant-select-selection-placeholder {
  font-size: 16px;
  color: hsl(0, 0%, 50%);
}
.ant-select-selection-item-remove {
  margin-top: -2px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fce6e7;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  margin-top: -3px;
  color: #e10c19;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #007aff;
  box-shadow: inset 0 0 0 1px #007aff;
  background-color: #f4f7fc;
}

.ant-select-item-option-content {
  white-space: pre-wrap;
}

.quick-pay {
  .ant-select-selector {
    height: 48px !important;
  }
  .ant-select-dropdown {
    border: 1px solid #dfdfdf;
  }
  .ant-select-item {
    min-height: 40px;
  }
  .ant-select-selection-search,
  .ant-select-selector {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-search-input,
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    height: 30px !important;
    line-height: 30px !important;
    font-weight: normal !important;
    font-size: 16px !important;
    color: #808080 !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-search-input {
    color: hsl(0, 0%, 20%) !important;
  }
  .ant-select-item-option-selected {
    background-color: #e20b19 !important;
  }
  .ant-select-item-option {
    font-size: 16px;
    font-weight: normal;
    color: #808080;
  }
  .ant-select-item-option-selected .ant-select-item-option-content {
    color: white;
  }
  .ant-select-item-option-content {
    height: 32px;
    line-height: 32px;
  }
  .rc-virtual-list-holder-inner {
    padding: 0px 8px;
  }
  .not-found {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: hsl(0, 0%, 60%);
    padding: 8px 12px;
    text-align: center;
    box-sizing: border-box;
  }
}

.ant-select.is-invalid .ant-select-selector {
  border: 1px solid $SCG-red !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).is-invalid .ant-select-selector {
  box-shadow: inset 0 0 0 1px $SCG-red !important;
}
