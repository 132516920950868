.switch-channel-page {
  .channel-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 320px;
    overflow-y: auto;
    padding: 2px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 50px;
      max-height: 50px;
      padding: 0 15px;
      outline: 1px solid $light-gray;
      border-radius: 5px;
      transition: all 0.3s;
      .name {
        color: $dark-gray;
      }
      .check {
        display: none;
        color: $SCG-red;
      }
      &.selected,
      &:hover {
        cursor: pointer;
        outline: 2px solid $SCG-red;
        background-color: rgba($SCG-red, 0.2);
        .name {
          font-weight: bold;
          color: $black;
        }
      }
      &.selected .check {
        display: block;
      }
    }
  }
}
