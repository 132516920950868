.payment-description-modal-body {
    padding: 35px 35px 20px 35px;
    .title {
        text-align: center;
        margin-bottom: 20px;
    }
}

.payment-description-modal-footer {
    border-top: none;
    justify-content: center;
    .nexter-btn {
        min-width: 142px;
    }
    * > .cancel{
        min-width: 90px;
        margin-right: -20px;
    }
}