#header-container {
  width: 100%;
  height: $navbar-height;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: $black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  .logo-link {
    line-height: $navbar-height;
    text-decoration: none;
    .logo-img {
      max-height: 36px;
      margin-right: 0.5rem;
    }
    .logo-text {
      color: $white;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  .shop-text {
    font-size: 20px;
  }
  #admin-privilege {
    width: 250px;
  }
  #user-dropdown {
    #user-toggle {
      height: 36px;
      padding: 6px 12px;
      margin: 0;
      background-color: $dark-gray;
      border-color: $dark-gray;
      &:hover {
        background-color: $dark !important;
        border-color: $dark !important;
      }
      i { font-size: 18px; }
      span { vertical-align: 2px; }
      &[aria-expanded="true"] i.fa-chevron-down {
        transform: rotateX(180deg);
      }
    }
  }
  #user-menu {
    .font-size-sm { font-size: 12px; }
    [class*="dropdown-"] { color: $dark-gray; }
    .dropdown-item:hover { background-color: $SCG-red; }
  }
}
