.icon-s-32-circle {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 8px 1px #eee;
  -moz-box-shadow: 0 0 8px 1px #eee;
  box-shadow: 0 0 8px 1px #eee;
  object-fit: scale-down;
}

.period-panel {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 4px;
}

.btn-nexter.btn-secondary {
  border-color: #e20a1a;
  background-color: #e20a1a;
  color: #ffffff;
}

.btn-outline-nexter.btn-secondary {
  border-color: #e20a1a;
  background-color: #fff;
  color: #e20a1a;
}

.btn-nexter.btn-secondary.active,
.btn-nexter.btn-secondary:hover,
.btn-nexter.btn-secondary:focus {
  background-color: #af0815 !important;
  border-color: #af0815 !important;
}

.btn-outline-nexter.btn-secondary.active,
.btn-outline-nexter.btn-secondary:hover,
.btn-outline-nexter.btn-secondary:focus {
  border-color: #af0815 !important;
  background-color: #fee !important;
  color: #af0815 !important;
}

.btn-outline-nexter.btn-secondary.disabled {
  border-color: #9a9a9a !important;
  background-color: #eee !important;
  color: #9a9a9a !important;
}
