$SCG-red: #e20a1a;
$fire-brick: #af0815;
$blue: #007aff;
$navy-blue: #1155cc;
$blue-smoke: #f4f7fc;
$green: #07b53b;
$green-leaf: #05a005;
$orange: #fba422;
$failed: #dc3545;

$black: #161616;
$dark: #393939;
$dark-gray: #525252;
$dim-gray: #6f6f6f;
$gray: #8d8d8d;
$light-gray: #a8a8a8;
$silver: #c6c6c6;
$light-silver: #e0e0e0;
$white-smoke: #f4f4f4;
$white: #ffffff;

$content-bgcolor: #f4f7fc;
$navbar-height: 64px;
