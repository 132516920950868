%fontsize-14-template {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
}
%text-status {
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.24px;
}

//Filter
.filter-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $SCG-red;
}
.filter-btn {
  padding-right: 10px;
}
.filter-pending-btn {
  padding-top: 25px;
}
.seller-modal-body {
  padding-bottom: 0 !important;
}
.seller-modal-footer {
  padding-top: 0 !important;
  border-top: none;
  .nexter-btn {
    min-width: 142px;
  }
}

//Card
.card-seller-management {
  padding: 22px 16px 24px;
  border-radius: 0;
  box-shadow: none;
  .card-header {
    padding: 0;
  }
  .card-body {
    padding: 0;
  }
  .text-second-seller {
    @extend %fontsize-14-template;
    color: $gray;
    margin: 0;
  }
  .calendar-datepicker {
    position: absolute;
    right: 0;
    top: 4px;
    padding-right: 14px;
  }
  ::placeholder,
  .react-select__placeholder {
    @extend %fontsize-14-template;
    color: $dark-gray;
  }
  .column-export {
    max-width: 200px !important;
    .form-group {
      margin-top: 5px;
    }
    .form-check-label {
      @extend %fontsize-14-template;
      color: $black;
    }
  }

  //DatePicker
  .react-datepicker__input-container {
    .button-datepicker {
      width: 100%;
      height: 36px;
      text-align: left;
      border-radius: 8px;
      border: solid 1px $gray !important;
      background-color: $white !important;
      color: $black !important;
      padding: 6px 12px;
      .calendar-datepicker {
        float: right;
      }
    }
    .pending {
      color: $orange;
    }
    .created {
      color: $light-gray;
    }
    .approved {
      color: $green-leaf;
    }
    .rejected {
      color: $SCG-red;
    }
    .incompleted,
    .incomplete {
      color: $blue;
    }
    .hr-seller {
      border: solid 1px $dark-gray;
      margin-top: 10px;
    }
    .seller-table {
      table-layout: fixed;
      margin-top: 19px;
      .td-seller-code,
      .td-status-ac,
      .td-status-kyc,
      .td-seller-type,
      .td-bank-name,
      .td-export,
      .td-phone {
        width: 120px;
      }
      .td-channel-id {
        width: 178px;
      }
      .td-company-name {
        width: 180px;
      }
      .td-service-charge-absorb {
        width: 80px;
      }
      thead > tr > th {
        padding-bottom: 24px;
      }
    }
    .thead-seller {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      line-height: 1.57;
      color: $black;
    }
    .tbody-seller {
      @extend %fontsize-14-template;
      color: $dark-gray;
    }
  }

  //Seller Detail
  .card-seller-detail {
    padding: 22px 16px 24px;
    border-radius: 0;
    box-shadow: none;
    .card-header {
      padding: 0;
    }
    .card-body {
      padding: 0;
    }
    .text-data-seller {
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: $navy-blue;
      margin-top: 4px;
      margin-bottom: 0;
    }
    .info-icon {
      font-size: 18px;
      color: $silver;
    }
    .text-label {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: $black;
    }
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__header {
      background-color: $white;
      border-bottom: none;
    }
    .react-datepicker__day {
      &:hover {
        border-radius: 20px;
      }
    }
    .react-datepicker__month-container {
      background-color: $white;
      .react-datepicker__current-month {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        color: $black;
      }
      .react-datepicker__day-name {
        width: 34px;
        height: 34px;
        line-height: 1.81;
        color: $black;
        padding-top: 3px;
      }
      .react-datepicker__day {
        width: 34px;
        height: 34px;
        padding-top: 3px;
      }
      .react-datepicker__day--today {
        width: 34px;
        height: 34px;
        font-weight: normal;
        color: $SCG-red;
        background-color: $white !important;
        border-radius: 30px;
        border: solid 1px $SCG-red;
        padding-top: 3px;
      }
    }
    .nexter-bt-incompleted,
    .nexter-bt-incomplete {
      cursor: default !important;
      color: $black;
      border: solid 1px $blue !important;
      background-color: rgba(0, 122, 255, 0.1);
      &:hover,
      &:focus {
        color: $black !important;
        border: solid 1px $blue !important;
        background-color: rgba(0, 122, 255, 0.1) !important;
      }
      .fa-file-alt {
        color: $blue;
      }
    }
  }

  //Pending Seller Page
  .nexter-input,
  .react-select__control {
    font-size: 14px;
    height: 36px;
    border: solid 1px $light-gray;
  }
  .row2-col-pending-seller {
    height: 50px;
  }
  .text-status {
    @extend %text-status;
    color: $black;
  }
  .pending {
    color: $orange;
  }
  .created {
    color: $light-gray;
  }
  .approved {
    color: $green-leaf;
  }
  .rejected {
    color: $SCG-red;
  }
  .incompleted, .incomplete {
    color: $blue;
  }
  .hr-seller {
    border: solid 1px $dark-gray;
    margin-top: 10px;
  }
  .seller-table {
    table-layout: fixed;
    margin-top: 19px;
    .td-seller-code,
    .td-status-ac,
    .td-status-kyc,
    .td-seller-type,
    .td-bank-name,
    .td-export,
    .td-phone {
      width: 120px;
    }
    .td-channel-id {
      width: 178px;
    }
    .td-company-name {
      width: 180px;
    }
    .td-service-charge-absorb {
      width: 80px;
    }
    thead > tr > th {
      padding-bottom: 24px;
    }
  }
  .thead-seller {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1.57;
    color: $black;
  }
  .tbody-seller {
    @extend %fontsize-14-template;
    color: $dark-gray;
  }
}

//Seller Detail
.card-seller-detail {
  padding: 22px 16px 24px;
  border-radius: 0;
  box-shadow: none;
  .card-header {
    padding: 0;
  }
  .card-body {
    padding: 0;
  }
  .text-data-seller {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: $navy-blue;
    margin-top: 4px;
    margin-bottom: 0;
  }
  .info-icon {
    font-size: 18px;
    color: $silver;
  }
  .text-label {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }
  .text-title {
    font-size: 16px;
    font-weight: bold;
  }
  .text-data {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: $dark-gray;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $white !important;
  }
  .select-bank-seller {
    .react-select__control {
      height: 48px;
    }
  }
  .form-control:disabled,
  .form-control[readonly] {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: $dim-gray;
    border: solid 1px $gray;
    background-color: $white-smoke;
  }
  .nexter-bt-pending {
    cursor: default !important;
    color: $black;
    border: solid 1px $orange !important;
    background-color: rgba(251, 164, 34, 0.1);
    &:hover,
    &:focus {
      color: $black !important;
      border: solid 1px $orange !important;
      background-color: rgba(251, 164, 34, 0.1) !important;
    }
    .fa-clipboard-list {
      color: $orange;
    }
  }
  .nexter-bt-created {
    cursor: default !important;
    color: $black;
    border: solid 1px $light-gray !important;
    background-color: rgba(141, 141, 141, 0.1);
    &:hover,
    &:focus {
      color: $black !important;
      border: solid 1px $light-gray !important;
      background-color: rgba(141, 141, 141, 0.1) !important;
    }
    .fa-file-alt {
      color: $light-gray;
    }
  }
  .nexter-bt-approved {
    cursor: default !important;
    color: $black;
    border: solid 1px $green-leaf !important;
    background-color: rgba(7, 181, 59, 0.1);
    &:hover,
    &:focus {
      color: $black !important;
      border: solid 1px $green-leaf !important;
      background-color: rgba(7, 181, 59, 0.1) !important;
    }
    .fa-check-circle {
      color: $green-leaf;
    }
  }
  .nexter-bt-rejected {
    cursor: default !important;
    color: $black;
    border: solid 1px $SCG-red !important;
    background-color: rgba(226, 10, 26, 0.1);
    &:hover,
    &:focus {
      color: $black !important;
      border: solid 1px $SCG-red !important;
      background-color: rgba(226, 10, 26, 0.1) !important;
    }
    .fa-times-circle {
      color: $SCG-red;
    }
  }
  .nexter-bt-incompleted, .nexter-bt-incomplete {
    cursor: default !important;
    color: $black;
    border: solid 1px $blue !important;
    background-color: rgba(0, 122, 255, 0.1);
    &:hover,
    &:focus {
      color: $black !important;
      border: solid 1px $blue !important;
      background-color: rgba(0, 122, 255, 0.1) !important;
    }
    .fa-file-alt {
      color: $blue;
    }
  }
  .nexter-bt-Rejected {
    color: $white;
    border-radius: 4px;
    border: solid 1px $dark-gray !important;
    background-color: $dark-gray;
    &:hover,
    &:focus {
      color: $white !important;
      border: solid 1px $dark-gray !important;
      background-color: $dark-gray !important;
    }
    .fa-times-circle {
      color: $white;
    }
  }
  .nexter-bt-Approve {
    color: $white;
    border-radius: 4px;
    border: solid 1px $green-leaf !important;
    background-color: $green-leaf;
    &:hover,
    &:focus {
      color: $white !important;
      border: solid 1px $green-leaf !important;
      background-color: $green-leaf !important;
    }
    .fa-check-circle {
      color: $white;
    }
  }
  .box-rejected {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: $black;
    background-color: rgba(226, 10, 26, 0.1);
    padding: 12px 16px 13px;
    .text-reason {
      font-weight: bold;
    }
    .history-seller-table {
      table-layout: fixed;
      .td-date-his {
        width: 150px;
      }
      .td-editby-his,
      .td-channel-his,
      .td-field-his {
        width: 158px;
      }
      .td-active-his {
        width: 88px;
      }
      .td-olddata-his,
      .td-newdata-his {
        width: 196px;
      }
    }
    .thead-seller {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      line-height: 1.57;
      color: $black;
    }
    .tbody-seller-history {
      @extend %fontsize-14-template;
      color: $black;
    }
    .box-action {
      font-size: 10px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: 0.24px;
      text-align: center;
      color: $black;
      .box-action-update {
        padding: 3px 4px;
        border-radius: 4px;
        border: solid 1px $orange;
        background-color: rgba(255, 214, 10, 0.24);
      }
      .box-action-add {
        padding: 3px 4px;
        border-radius: 4px;
        border: solid 1px $green-leaf;
        background-color: rgba(7, 181, 59, 0.12);
      }
      .box-action-delete {
        padding: 3px 4px;
        border-radius: 4px;
        border: solid 1px $fire-brick;
        background-color: rgba(226, 10, 26, 0.08);
      }
    }
    .form-check-label {
      color: $black;
    }
  }
}

  //Modal Imaage
  .modal-image-content {
    background: transparent;
    border: 0;
  }
  .modal-image-header {
    padding: 0;
  }
  .modal-image-body {
    background-color: $white;
  }
  .modal-image-body img {
    width: 100%;
  }
  .modal-image-body .body-inner {
    background-color: $light-silver;
    padding: 0.775rem;
    font-size: 0.775rem;
  }
  .modal-image-close {
    font-size: 25px;
    background: transparent;
    border: 0;
    color: $white;
    padding: 0;
  }

//Upload Image
.upload-image {
  // height: 68px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: $content-bgcolor;
  .box-file-container {
    margin-top: auto;
    margin-bottom: auto;
  }
  .box-file-image {
    display: flex;
    align-items: center;
    min-height: 36px;
    width: fit-content;
    border-radius: 4px;
    border: solid 1px rgba(0, 122, 255, 0.4);
    background-color: rgba(0, 122, 255, 0.1);
    padding: 4px 8px;
    margin: 16px 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    .text-name-image {
      color: $blue;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .text-placeholder {
    color: $gray;
  }
  .far,
  .fas {
    cursor: pointer;
  }
}

/// CREATE SELLER

.create-seller-wrapper {
  color: $black;
  font-size: 14px;
  .link-back,
  .form-label {
    color: $black;
  }
  .title {
    color: $navy-blue;
    margin-bottom: 0;
    font-size: 20px;
  }
  .sub-title {
    color: $black;
    margin-bottom: 16px;
    font-size: 18px;
  }
  .sub-title-2 {
    color: $black;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.shareholder-container {
  border: 1px solid $light-silver;
  border-radius: 6px;
  .shareholder-header {
    padding: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white-smoke;
  }
  .shareholder-content {
    padding: 17px;
  }
}

.seller-notice-alert {
  padding: 9px;
  background-color: rgba($blue, 0.1);
  border-radius: 6px;
  color: $navy-blue;
  font-size: 14px;
}

/// CREATE SELLER

.history-seller-table {
  table-layout: fixed;
  .td-date-his {
    width: 150px;
  }
  .td-editby-his,
  .td-channel-his,
  .td-field-his {
    width: 158px;
  }
  .td-active-his {
    width: 88px;
  }
  .td-olddata-his,
  .td-newdata-his {
    width: 196px;
  }
}
.thead-seller {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.57;
  color: $black;
}
.tbody-seller-history {
  @extend %fontsize-14-template;
  color: $black;
}
.box-action {
  @extend %text-status;
  text-align: center;
  color: $black;
  .box-action-update {
    padding: 3px 4px;
    border-radius: 4px;
    border: solid 1px $orange;
    background-color: rgba(255, 214, 10, 0.24);
  }
  .box-action-add, .box-action-create {
    padding: 3px 4px;
    border-radius: 4px;
    border: solid 1px $green-leaf;
    background-color: rgba(7, 181, 59, 0.12);
  }
  .box-action-delete {
    padding: 3px 4px;
    border-radius: 4px;
    border: solid 1px $fire-brick;
    background-color: rgba(226, 10, 26, 0.08);
  }
}

//Text Status is null
.data-is-null {
  @extend %text-status;
  color: $blue;
  padding: 3px 4px;
  border-radius: 4px;
  background-color: $white-smoke;
}

.seller-mass-err-list {
  li {
    list-style: initial;
    display: list-item;
  }
}