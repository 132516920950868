.table-channel {
  td {
    font-size: 14px;
    &.td-status {
      font-size: 10px;
      .create {
        color: $light-gray;
      }
      .pending {
        color: $orange;
      }
      .approved {
        color: $green-leaf;
      }
      .rejected {
        color: $SCG-red;
      }
    }
  }
}

i.channel-status {
  &.active {
    color: $green-leaf;
  }
  &.inactive {
    color: $SCG-red;
  }
}

.create-channel-wrapper {
  font-size: 14px;
  .vertical-line {
    height: 28px;
    border-right: 1px solid $light-gray;
    &:last-child {
      display: none;
    }
  }
  hr {
    border-color: $silver;
  }
  .link-back, .form-label {
    color: $black;
  }
  .text-red {
    color: $SCG-red;
  }
  .bank-icon {
    object-fit: contain;
  }
  .title-channel-info {
    color: $black;
    margin-bottom: 0;
  }
  .title-contact-person, .title-api-configuration, .title-channel-info-view {
    color: $navy-blue;
    margin-bottom: 0;
  }
  .text-description {
    color: $dark-gray !important;
  }
  .item-remove-contact-person {
    color: $SCG-red;
    cursor: pointer;
  }
  .logo-upload-btn {
    text-transform: none;
    font-weight: 600;
  }
  .logo-upload-text {
    font-size: 12px;
    color: $SCG-red;
  }
  .btn-application-form {
    text-transform: none;
  }
  .checkbox-payment-wrapper {
    background-color: $blue-smoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo-upload-container {
    .errorsContainer {
      order: 2;
    }
    .chooseFileButton {
      order: 1;
    }
  }
  .installment-interest-group {
    width: 190px;
    @include rwd(1400) {
      width: 290px;
    }
  }
  .pending-container {
    height: 36px;
    color: $black;
    .fas {
      color: $orange;
    }
    border: 2px solid $orange;
    display: flex;
    align-items: center;
  }
  .alert-rejected {
    display: flex;
    height: 48px;
    margin: 16px 0 24px 0;
    padding: 14px 16px;
    color: $black;
    border: solid 1px rgba(226, 10, 26, 0.4);
    background-color: rgba(226, 10, 26, 0.06);
    font-size: 14px;
    .fas {
      font-size: 24px;
    }
  }
  .feedback {
    color: $SCG-red;
    font-size: 12px;
  }
  .custom-radio-fee {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    width: 20px;
    height: 20px;
    input[type='radio'] {
      width: inherit;
      height: inherit;
      cursor: pointer;
      &::before {
        content: '';
        border-radius: 100%;
        border: 1px solid $gray;
        width: 100%;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        pointer-events: none;
        z-index: 0;
      }
      &::after {
        content: '';
        border-radius: 100%;
        background-color: transparent;
        width: 60%;
        height: 60%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        box-sizing: border-box;
        pointer-events: none;
        z-index: 0;
      }
      &:checked {
        &::after { background-color: $SCG-red;}
        &::before { border-color: $SCG-red; }
        &:disabled {
          &::before { border-color: $silver; }
          &::after { background-color: $silver; }
        }
      }
      &:disabled {
        cursor: default;
        &::before { border-color: $silver; }
      }
    }
  }
  .custom-invalid-feedback-fee {
    padding-left: 20px;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}

.logo-thumbnail {
  border: 1px dashed $silver;
  padding: 9px;
  &-container {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-smoke;
    position: relative;

    .remove-container {
      position: absolute;
      top: -5px;
      right: -5px;
      color: $dark-gray;
      background-color: $white;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.file-application-form {
  height: 36px;
  border: 1px solid $silver;
  border-radius: 8px;
  color: $gray;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.approved-wrapper {
  display: flex;
  align-items: center;
}
.approved-container {
  height: 36px;
  color: $black;
  .fas {
    color: $green-leaf;
  }
  border: 2px solid $green-leaf;
  display: flex;
  align-items: center;
}

.channel-setting-wrapper, .create-campaign-wrapper, .create-campaign-modal-wrapper {
  font-size: 14px;
  hr {
    border-color: $silver;
  }
  .link-back, .form-label {
    color: $black;
  }
  .title-channel {
    color: $navy-blue;
    margin-bottom: 0;
  }
  .campaign-search-wrapper {
    .control-label {
      color: $gray;
    }
  }
  .checkbox-payment-wrapper {
    background-color: $blue-smoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.create-campaign-wrapper {
  .campaign-date {
    width: 140px;
  }
  .text-length {
    color: $dark-gray;
  }
}
.installment-card-img {
  object-fit: contain;
}
.installment-card-wrapper {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1.5px solid $blue;
  color: $black;
  font-size: 16px;
  .ic-remove-btn {
    position: absolute;
    top: 7px;
    right: 22px;
    color: $dark-gray;
    cursor: pointer;
  }
}