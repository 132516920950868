.channel-fee-card, .channel-payment-option-card, .due-payment-card {
  border: none;
  &.active {
    border: 1px solid $blue;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
}
.custom-input-group {
  border: 1px solid $gray;
  border-radius: 4px;
  transition:
    box-shadow 0.4s,
    border-color 0.4s,
    background-color 0.4s;
  &:hover, &:focus, &:active, &.active {background-color: $blue-smoke;}
  &:focus, &:active, &.active {
    border-width: 0.1rem;
    box-shadow: inset 0 0 0 1px $blue;
    input {color: $black !important;}
    &:not(.disabled) {border-color: $blue;}
  }

  input {
    border-radius: 4px 0 0 4px;
    color: $light-gray;
    font-weight: 900;
    font-size: 14px;
    border: none;
    &:disabled { color: $silver; }
  }

  .input-group-text {
    border-radius: 0 4px 4px 0 !important;
    border: none;
  }
}

.title-back {
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  &:hover {
    color: $blue;
  }
}

.text-blue {
  color: $blue;
}

.text-green {
  color: $green;
}

.text-failed {
  color: $failed;
}