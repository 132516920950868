.reconcile-export input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 3px;
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  border-radius: 50%;
}

.reconcile-export input[type='radio']:checked {
  background-color: #e20b19;
  border-color: #e20b19;
}
