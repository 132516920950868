.promotion-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-top: 15px;
    color: #0f50c1;
    font-size: 20px;
    font-weight: 600;
  }

  .promotion-btn-row {
    padding: 15px;
    .sort-dropdown {
      background: #fff;
      color: $SCG-red;
      margin: 0px;
    }
  }
  .promotion-btn {
    margin-right: 15px;
  }

  .promotion-btn-outline {
    color: $SCG-red;
    background: #fff;
    border: 1px solid $SCG-red;
    border-radius: 10px;
  }
}
.promotion-modal {
  .modal-body {
    .form-group.pm-upload {
      padding: 10px;
      background: #ddd;
      border-radius: 8px;
    }
    .btn-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .btn {
        width: 49%;
        padding: 0;
        flex: unset;
        border-radius: 8px;
      }
    }
    .icon-calendar {
      .nexter-input {
        width: 100%;
      }
    }
    .btn-active.choose-inactive:not(:hover), .btn-inactive.choose-active:not(:hover) {
      border-color: #999 !important;
      color: #999;
      z-index: 0 !important;
    }
    .btn.active {
      z-index: 0 !important;
    }
    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      color: #fff;
      background-color: rgb(255, 158, 158);
      border-color: rgb(255, 158, 158);
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: $SCG-red;
      background-color: $SCG-red;
    }
    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: rgb(255, 158, 158);
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(255, 158, 158, 0.25)
    }
    .custom-control {
      z-index: 0 !important;
    }
    .btn-inactive {
      background-color: #ffffff;
      border: solid 1px $SCG-red;

      color: $SCG-red;
    }
    .btn-inactive-click {
      background-color: $SCG-red;
      border: solid 1px $SCG-red;

      color: #fff;
    }
    .prev-img {
      position: relative;
      margin: 10px 0;
      display: inline-block;
      button {
        top: 0;
        right: 0px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.75);
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
    .button-upload {
      text-transform: capitalize;
      font-weight: normal;
      padding: 8px 12px;
      margin: 0px;
      color: #fff;
      background: #525252;
      border-radius: 6px;
      cursor: pointer;
    }
    .upload-input {
      position: absolute;
      height: 100%;
      width: 100%;
      text-align: right;
    }
    .upload-text {
      position: absolute;
      height: 100%;
      width: 79%;
      padding: 6px;
    }
  }

  .promotion-select-error {
    border: 1px solid red;
    border-radius: 6px;
  }

  .modal-date-picker {
    position: relative;
  }

  .CREATE {
    width: 78px;
    height: 24px;
    margin: 0 47px 24px 2px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #0f50c1;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    border: 0px;
    .promotion-modal-btn {
      border-radius: 10px;
      width: 100%;
    }
    .promotion-modal-btn:disabled {
      color: #fff !important;
      background: #666 !important;
    }
  }
}
.modal-read-more {
  top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotion-list-card {
  min-height: 326px;
  margin: 16px 0px;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #f8f8f8;

  .promotion-text {
    margin-top: 20px;
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #161616;
  }
  .promotion-text:hover {
    overflow: visible;
  }
  .line-seperate {
    border-right: solid 1px #c6c6c6;
    height: 130px;
    width: 100%;
  }

  .bank-header {
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 15px;
  }
  .bank-img {
    width: 32px;
    height: 32px;
    margin: 16px 10px;
    object-fit: contain;
  }
  .bank-title {
    font-family: Sarabun;
    font-size: 20px !important;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .remove-promotion-btn {
    margin-right: 15px;
  }
  .promotion-btn-outline {
    color: $SCG-red;
    background: #fff;
    border: 1px solid $SCG-red;
    border-radius: 10px;
    margin-right: 15px;
  }
  .promotion-list-image {
    width: 120px;
    height: 120px;
    margin: 16px;
    object-fit: contain;
  }

  .promotion-payment-method {
    margin-top: 8px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
  }
  .payment-method-row {
    display: block;
    .payment-method-list {
      display: inline-block;
      min-width: 86px;
      margin: 4px;
      text-align: center;
      padding: 8px 15px;
      border-radius: 30px;
      background-color: #e0e0e0;
    }
  }

  .promotion-btn-outline {
    color: $SCG-red;
    background: #fff;
    border: 1px solid $SCG-red;
    border-radius: 10px;
    margin-right: 15px;
  }
  .promotion-list-image {
    width: 120px;
    height: 120px;
    margin: 16px auto;
    object-fit: contain;
  }

  .promotion-description {
    height: 62px;

    font-family: Sarabun;
    font-size: 14px;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    color: #161616;
  }
  .read-more {
    color: $SCG-red;
    text-decoration: underline;
    margin: 5px 0px;
    cursor: pointer;

    text-align: right;
  }
  .read-more-channel {
    color: $SCG-red;
    text-decoration: underline;
    margin: 16px 0px;
    cursor: pointer;
  }
  .modal-channel {
    top: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hr-system {
  margin-top: 0px;
  margin-bottom: 0px;
}
.img-promotion {
  display: inline-block;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}
.close-preview {
  position: absolute;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  right: 0;
  border-color: transparent;
  background-color: transparent;
}

.fa-icon {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.folder-before::before,
.folder-after::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f07b';
}

.drop-area {
  border: 1px dotted black;
}

.dropdown-container {
  position: absolute;
  z-index: 9999;
}

.dropdown-container .dropdown-content .options li label {
  width: 100%;
  margin: 0;
  padding: 0.5rem;
}

.dropdown-container .dropdown-content .options input[type='checkbox'] {
  -ms-transform: scale(1.25); /* IE */
  -moz-transform: scale(1.25); /* FF */
  -webkit-transform: scale(1.25); /* Safari and Chrome */
  -o-transform: scale(1.25); /* Opera */
  transform: scale(1.25);
  margin-right: 0.5rem;
}

.promotion-modal .modal-date-picker .button-datepicker {
  border-radius: 0.25rem !important;
  border-color: #ccc !important;
}

.promotion-modal .modal-date-picker.is-invalid .button-datepicker {
  border-color: #dc3545 !important;
}
