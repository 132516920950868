//User Management
.card-usermanagement {
    padding: 22px 16px 24px;
    border-radius: 0;
    box-shadow: none;
    .card-header {
        padding: 0;
    }
    .card-body{
        padding: 0;
    }
    .texthead-managementpage {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #0f50c1;
        margin-bottom: 0;
        padding: 6px 0;
    }
    .nexter-input {
        height: 36px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        color: $dark-gray;
        padding-left: 12px;
    }
    .dropdown-descript {
        color: $black;
        background-color: $white;
        margin: 0;
        padding: 0;
        border: none !important;
        &:hover {
            color: $black !important;
            background-color: $white !important;
        }
        &:focus {
            background-color: $white !important;
        }
    }
    .filter-status-options {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        color: $black;
    }
    .input-status {
        color: $black;
        input[type='radio'] {
            width: 20px;
            height: 20px;
            margin-top: 3px;
            &::before {
                content: '';
                border-radius: 100%;
                border: 2px solid $black;
                width: 100%;
                height: 100%;
                position: absolute;
                box-sizing: border-box;
                pointer-events: none;
                z-index: 0;
            }
        }
        .label-status {
            padding-left: 12px;
        }
    }
    .form-check .form-check-label {
        padding-left: 36px;
    }
    
    //Select dropdown status
    .react-select__value-container {
        font-size: 14px;
    }
    .css-1pcexqc-container {
        font-size: 13px;
        font-weight: normal;
        line-height: 1.5;
        .css-kj6f9i-menu {
            color: $black;
        }
    }
    .react-select__menu {
        .react-select__option--is-disabled {
            &:hover {
                color: $silver !important;
                background-color: transparent !important;
            }
        }
    }

    //Table
    tr.border-descript td {
        border: 0;
    }
    .table-user {
        table-layout: fixed;
    }
    .td-collapsible {
        width: 64px;
    }
    .td-fname, .td-lname, .td-action {
        width: 140px;
    }
    .td-type {
        width: 120px;
    }
    .td-mail, .td-chname {
        width: 160px;
    }
    .td-status {
        width: 105px;
    }
    .td-date {
        width: 107px;
    }
    .updateby {
        width: 280px;
    }
    .text-head-descript {
        font-weight: 500;
        color: $dark-gray;
    }

    //User Info
    .text-user-info-h3 {
        font-size: 14px;
        font-weight: normal;
        color: $black;
        line-height: 1.57;
        margin: 0;
        padding-bottom: 10px;
    }
    .text-user-info-h3-disable {
        font-size: 14px;
        font-weight: normal;
        color: $silver;
        line-height: 1.57;
        margin: 0;
        padding-bottom: 10px;
    }
    .text-user-info-h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: $black;
    }
    .text-user-info-descript {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        color: $black;
    }
    .nexter-input:disabled {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $black;
        border: solid 1px $gray;
        background-color: $white-smoke;
    }
    .nexter-select {
        text-transform: capitalize;
        .react-select__control--is-disabled{
            border: solid 1px $gray;
            background-color: $white-smoke;
        }
        .react-select__single-value--is-disabled {
            font-size: 14px;
            color: $black;
        }
    }
    .nexter-default-outline  {
        color: $dark-gray !important;
        border: solid 1px $dark-gray;
        background-color: $white;
        &:hover {
            color: $dark-gray !important;
            border: solid 1px $dark-gray !important;
            background-color: $white-smoke !important;
        }
        &:focus {
            color: $dark-gray !important;
            border: solid 1px $dark-gray !important;
            background-color: $white-smoke !important;
        }
    }
    .select-nonchannel {
        .react-select__control--is-disabled{
            border: solid 1px $silver;
            background-color: $white;
        }
    }
    .descript-user-info {
        margin-top: 24px;
    }
    .descript-last-user-info {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .user-info-link {
        color: $blue;
    }

    //History Log
    .nav-history-log {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        border-bottom: solid 1px $silver;
        margin: auto;
        .nav-tabs .nav-link.active {
            color: $SCG-red;
            border-top: none !important;
            border-bottom: 2px solid $SCG-red;
            padding-top: 0px;
            padding-bottom: 14px;
        }
        .nav-item .nav-link {
            padding-top: 0px;
            color: $dim-gray;
            background-color: $white;
            border: none;
            padding-bottom: 14px;
            border-bottom: solid 1px $silver;
        }
    }
    .user-log-table {
        table-layout: fixed;
        margin-top: 16px;
        .td-date {
            width: 107px;
        }
        .td-editby, .td-edit-order, .td-edit-data {
            width: 160px;
        }
        .td-edit-type {
            width: 100px;
        }
        .td-olddata {
            width: 214px;
        }
        .td-newdata {
            width: 211px;
        }
    }
    .thead-user {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        line-height: 1.57;
        color: $black;
    }
    .tbody-user {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        color: $dark-gray;
    }
}
