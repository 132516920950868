.nexter-btn {
  border-width: 2px;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin: 0;

  // Medium //

  height: 48px;
  border-radius: 8px;
  font-size: 18px;

  // Small //

  &.btn-sm {
    height: 36px;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 15px;
  }

  // Large //

  &.btn-lg {
    height: 60px;
    border-radius: 12px;
    font-size: 22px;
  }

  // Primary //

  &.btn-primary {
    background-color: $SCG-red;
    border-color: $SCG-red;
    &.active,
    &:hover,
    &:focus {
      background-color: $fire-brick !important;
      border-color: $fire-brick;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $SCG-red !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white-smoke !important;
      border-color: $white-smoke !important;
    }
  }
  &.btn-outline-primary {
    color: $SCG-red;
    background-color: $white;
    border-color: $SCG-red !important;
    &.active,
    &:hover,
    &:focus {
      color: $fire-brick !important;
      background-color: rgba(175, 8, 21, 0.05) !important;
      border-color: $fire-brick !important;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $SCG-red !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white !important;
      border-color: $silver !important;
    }
  }

  // Secondary //

  &.btn-secondary {
    color: $dark-gray;
    background-color: $light-silver;
    border-color: $light-silver;
    &.active,
    &:hover,
    &:focus {
      color: $dark-gray !important;
      background-color: $silver !important;
      border-color: $silver;
    }
    &.active,
    &:focus,
    &:active {
      color: $dark-gray !important;
      border-color: $gray !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white-smoke !important;
      border-color: $white-smoke !important;
    }
  }
  &.btn-outline-secondary {
    color: $dark-gray;
    background-color: $white;
    border-color: $dark-gray !important;
    &.active,
    &:hover,
    &:focus {
      color: $dark-gray !important;
      background-color: $white-smoke !important;
      border-color: $dark-gray !important;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $dark-gray !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white !important;
      border-color: $silver !important;
    }
  }

  // Light //

  &.btn-light {
    color: $dark-gray;
    background-color: $white-smoke;
    border-color: $white-smoke;
    &.active,
    &:hover,
    &:focus {
      color: $dark-gray !important;
      background-color: $silver !important;
      border-color: $silver;
    }
    &.active,
    &:focus,
    &:active {
      background-color: $white-smoke !important;
      border-color: $white-smoke !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white-smoke !important;
      border-color: $white-smoke !important;
    }
  }
  &.btn-outline-light {
    color: $dark;
    background-color: $white;
    border-color: $dark-gray !important;
    border-width: 1px;
    &.active,
    &:hover,
    &:focus {
      color: $white !important;
      background-color: $gray !important;
      border-color: $dark-gray !important;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $dark !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white !important;
      border-color: $silver !important;
    }
  }

  // Dark //

  &.btn-dark {
    color: $white;
    background-color: $dark-gray;
    border-color: $dark-gray;
    &.active,
    &:hover,
    &:focus {
      background-color: $dark !important;
      border-color: $dark;
    }
    &.active,
    &:focus,
    &:active {
      background-color: $dark-gray !important;
      border-color: $gray !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white-smoke !important;
      border-color: $white-smoke !important;
    }
  }
  &.btn-outline-dark {
    color: $dark;
    background-color: $white;
    border-color: $dark-gray !important;
    border-width: 1px;
    &.active,
    &:hover,
    &:focus {
      color: $dark !important;
      background-color: $gray !important;
      border-color: $dark-gray !important;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $dark !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white !important;
      border-color: $silver !important;
    }
  }

  // Info //

  &.btn-info {
    background-color: $blue;
    border-color: $blue;
    &.active,
    &:hover,
    &:focus {
      background-color: $navy-blue !important;
      border-color: $navy-blue;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $blue !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white-smoke !important;
      border-color: $white-smoke !important;
    }
  }
  &.btn-outline-info {
    color: $navy-blue;
    background-color: $white;
    border-color: $navy-blue !important;
    &.active,
    &:hover,
    &:focus {
      color: $navy-blue !important;
      background-color: $blue-smoke !important;
      border-color: $navy-blue !important;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $navy-blue !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white !important;
      border-color: $silver !important;
    }
  }

  // Success //

  &.btn-success {
    background-color: $green;
    border-color: $green;
    &.active,
    &:hover,
    &:focus {
      background-color: $green-leaf !important;
      border-color: $green-leaf;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $green !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white-smoke !important;
      border-color: $white-smoke !important;
    }
  }
  &.btn-outline-success {
    color: $green-leaf;
    background-color: $white;
    border-color: $green !important;
    &.active,
    &:hover,
    &:focus {
      color: $green-leaf !important;
      background-color: rgba(5, 160, 5, 0.05) !important;
      border-color: $green !important;
    }
    &.active,
    &:focus,
    &:active {
      border-color: $green !important;
    }
    &.disabled {
      color: $silver !important;
      background-color: $white !important;
      border-color: $silver !important;
    }
  }
}

.group-form > :not(:last-child) button.button-datepicker {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.group-form > :last-child button.button-datepicker {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
