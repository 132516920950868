
.payment-title {
  color: $black;
  font-size: 20px;

  .back-icon {
    color: $SCG-red;
  }
  &-header {
    margin-left: -10px;
  }
}

.card-void, .card-refund {
  color: $black;
  hr {
    border-color: $silver !important;
    color: $silver !important; 
  }
}

.payment-info {
  hr {
    border-color: $light-silver !important;
    color: $light-silver !important; 
  }
  .text-link {
    color: $blue;
  }
  .info-title {
    font-weight: 700;
    color: $black;
  }
  .info-desc {
    color: $dark-gray;
  }
}

.card-payment-list, .card-report-r1, .payment-transfer-table {
  hr {
    border-color: $light-silver;
    color: $light-silver; 
  }
  &-sm {
    font-size: 11px;
  }
  table {
    thead {
      color: $black;
    }
    tbody {
      color: $dark-gray;
      & td {
        border-color: $light-silver;
        word-break: break-word;
        white-space: nowrap;
      }
      tr.tr-total {
        color: $black;
      }
      .ref-id {
        color: $blue;
        cursor: pointer;
      }
    }
  }
}

.create-transfer-panel {
  .text-seller {
    color: $black;
  }
  .react-select__control {
    border-color: $light-gray;
  }
}

.status {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #111;
  color: $white;
  &-created { color: $black;background-color: $white-smoke; }
  &-failed { background-color: $fire-brick; }
  &-success { background-color: $green-leaf; }
  &-settled { background-color: $blue; }
  &-voided, &-refunded { background-color: $light-gray; }
  &-pending { background-color: #ffd60a; color: $black; }
  &-cancel { color: $fire-brick; }
}

.transfer-status {
  text-transform: uppercase;
  font-weight: bold;
  background-color: $white-smoke;
  color: $blue;
  &-pre-transfer { background-color: $orange; color: $black; }
  &-transferred { color: $green-leaf; }
  &-pending { background-color: #ffd60a; color: $black; }
  &-transferring{ background-color: #D2A56D; color: $black; }
}

.modal-payment-filter {
  max-width: 560px !important;
}

.payment-channel-select {
  .react-select__control {
    border-color: $black;
  }
}

.datepicker-custom-text {
  color: $dark-gray;
}