.table-auto-transfer {
  td {
    font-size: 14px;
    &.td-status {
      font-size: 10px;
      font-weight: $font-weight-bold;
      .active {
        color: $blue;
      }
      .inactive {
        color: $light-gray;
      }
    }
  }
}

.filter-auto-transfer .nexter-input,
.filter-auto-transfer .react-select__control {
  font-size: 14px;
  height: 36px;
  border: solid 1px #a8a8a8;
}

.auto-transfer-detail {
  .form-label {
    color: $black;
    font-weight: $font-weight-bold;
  }
  .text-active-color {
    color: $blue;
  }
}

.schedule-month-date-selector {
  .calendar {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1px;
    max-width: 380px;
    .item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      flex-basis: calc(100% / 7 + 1px);
      border: 1px solid $silver;
      margin-top: -1px;
      margin-left: -1px;
      &:hover {
        background-color: rgba($SCG-red, 0.2);
      }
      &.selected {
        background-color: $SCG-red;
        color: $white;
      }
    }
  }
  .checkbox-end-date {
    .form-check-label {
      color: $black;
    }
  }
}

.schedule-week-day-selector {
  max-width: 380px;
}
