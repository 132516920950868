.card-channelmanagement {
  border-top-left-radius: 0;
}

.channel-page.nav-tabs .nav-item .nav-link {
  background-color: #e0e0e0;
  color: #6f6f6f;
  border-color: #e0e0e0;
  border-top: 3px solid #e0e0e0;
  font-weight: 700;
}

.channel-page.nav-tabs .nav-item .nav-link.active {
  background-color: #ffffff;
  color: #161616;
  border-color: #ffffff;
  border-top: 3px solid #e20a1a;
  font-weight: 700;
}
