.w-p-25 {
  width: 25%;
}

.void-table th,
.void-table td {
  border-top: 0 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.void-table thead th {
  border-top: 0 !important;
  border-bottom: 0 !important;
}
