$background-color-button: #e20a1a;
$background-color-hover-button: #af0815;
$color-white: #ffffff;
$color-black: #161616;
$color-border-solid: #8d8d8d;
$size-height-input: 48px;

.text-quickpay-h1 {
  font-size: 24px;
  color: $color-black;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.25;
  margin: 40px 0 42px 0;
}

.card-quickpay {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(72, 38, 8, 0.16);
  background-color: $color-white;
  border: 0 none;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-bottom: 24px;
  padding: 32px 40px 32px 40px;
  .ant-card-body {
    padding: 0;
  }
  .card-header {
    padding: 0;
    .text-quickpay-h2 {
      font-size: 20px;
      color: #0f50c1;
      font-weight: 600;
      line-height: 1.3;
      margin: 0;
      padding: 0 0 19px 0;
    }
  }
  .card-body {
    padding: 0;
    .text-quickpay-h3 {
      font-size: 14px;
      font-weight: normal;
      color: $color-black;
      line-height: 1.57;
      margin: 0;
      padding-bottom: 10px;
    }
    .create-payment {
      .text-description {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.5;
        color: $color-black !important;
      }
      .react-datepicker__input-container {
        .button-datepicker {
          width: 100%;
          text-align: left;
          border-radius: 8px;
          border: solid 1px $color-border-solid !important;
          background-color: $color-white !important;
          color: $color-black !important;
          padding: 6px 12px;
          .calendar-datepicker {
            float: right;
          }
        }
      }
      .react-datepicker {
        font-family: Inter, Sarabun, sans-serif;
        font-size: 16px;
        font-weight: normal;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: $color-white;
        border: none;
        margin: 4px 0 0;
        padding: 14px;
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range {
          color: $color-white !important;
          width: 34px;
          height: 34px;
          background-color: $background-color-button;
          border-radius: 30px;
          padding-top: 3px;
          &:hover {
            background-color: $background-color-hover-button;
          }
        }
        .react-datepicker__triangle {
          display: none;
        }
        .react-datepicker__header {
          background-color: $color-white;
          border-bottom: none;
        }
        .react-datepicker__day {
          &:hover {
            border-radius: 20px;
          }
        }
        .react-datepicker__month-container {
          background-color: $color-white;
          .react-datepicker__current-month {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            color: $color-black;
          }
          .react-datepicker__day-name {
            width: 34px;
            height: 34px;
            line-height: 1.81;
            color: $color-black;
            padding-top: 3px;
          }
          .react-datepicker__day {
            width: 34px;
            height: 34px;
            padding-top: 3px;
          }
          .react-datepicker__day--today{
            width: 34px;
            height: 34px;
            font-weight: normal;
            color: #e20a1a;
            border-radius: 30px;
            border: solid 1px #e20a1a;
            padding-top: 3px;
          }
        }
        .react-datepicker__navigation {
          outline: none;
        }
      }
    }
    .buyer-seller {
      .nexter-select {
        .react-select__control {
          height: 48px !important;
        }
      }
    }
    .order-product {
      .t-1 {
        padding: 0 8px 0 0;
      }
      .t-2,
      .t-3 {
        padding: 0 8px 0 8px;
      }
      .t-4 {
        padding: 0 0 0 8px;
      }
      .button-add-product {
        font-size: 16px;
        line-height: 1.38;
        padding: 6px 14px;
      }
      .text-total-price {
        text-align: right;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        color: $color-black;
        margin: 0;
        padding: 6px 0;
      }
    }
  }
  .hr-main {
    border-top: solid 1px #c6c6c6;
    margin: 24px 0;
  }
}

.button-quickpay-create {
  margin-top: 12px;
  padding: 0 105px;
}
