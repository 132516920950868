@import './paper-dashboard/variables';
@import './mixins';
@import './variables';
@import './fonts';
@import './header';
@import './sidebar';
@import './nexter-ui/nexter';
@import './quickpay';
@import './sweetAlert';
@import './systemConfig/config';
@import './pages/pages';
@import './transfer.scss';
@import './user-management.scss';
@import './role_permission.scss';
@import './seller_management.scss';
@import './channel-management.scss';
@import './dashboard.scss';
@import './select.scss';
@import './table.scss';
@import './reconcile.scss';
@import './utils';

.header-padding {
  padding-top: $navbar-height;
}

body {
  background-color: $content-bgcolor;
}

.content-container {
  height: calc(100vh - #{$navbar-height});
  padding: 1.5rem !important;
  overflow: auto;
  background-color: $content-bgcolor;
}

.fileContainer {
  box-shadow: 0px 0px;
}

.table-report {
  th {
    color: #0f50c1;
  }
  th,
  td,
  thead,
  tbody {
    border: none;
  }
}
.bold {
  font-weight: bold;
}
.border-top {
  border-top: $light-gray;
}
.p2 {
  padding: 16px;
}
.card-transfer {
  margin-bottom: 0px !important;
}
.ml-5px {
  margin-left: 5px;
}
.red {
  color: $SCG-red;
}
.dropdown.show {
  .dropdown-menu {
    top: 5px !important;
    transition: none;
  }
}

.custom-reacttable {
  .form-check {
    margin-bottom: 0;
  }

  .rt-thead {
    font-weight: bold;

    &.-header {
      box-shadow: none;
      border-bottom: 1px solid $border-color;
    }

    .rt-th,
    .rt-td {
      box-shadow: none;
      border: none;
      padding: 12px 7px;
      text-align: left;
    }
  }

  .rt-tbody {
    .rt-td {
      border: none;
      padding: 12px 7px;
    }
  }

  .rt-tr {
    align-items: center;
  }

  .-pagination {
    box-shadow: none;
    border: none;
    padding: 3px 0;
  }
}

.btn-group.btn-group-full-width {
  display: flex;

  .btn {
    flex: 1;
  }

  .btn-outline-default.disabled.active,
  .btn-outline-default:disabled.active,
  .btn-outline-default[disabled].active {
    background-color: $default-color !important;
    border-color: $default-color !important;
    opacity: 1;
  }
}

.custom-input-group {
  &.input-group .form-control:first-child {
    border-right: 1px solid #ddd;

    &:focus {
      border: 1px solid #9a9a9a;
    }
  }
}

.form-group.required .control-label:after {
  content: ' *';
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Loader
.css-oo3axg {
  @include absolute(top 50% left 50%);
}
.sweet-loading {
  @include absolute(top 0 left 0);
  z-index: 100000;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.6);

  > div {
    @include absolutecenter();
  }
}

// DatePicker
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker-popper {
  width: max-content;
  z-index: 15;
}

// print pdf
@media print {
  @page {
    size: auto !important;
    margin: 1.5cm 0 1.5cm !important;
  }
  body {
    margin-top: 0px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  .header-padding {
    padding-top: 0px;
  }

  #wrapper {
    padding-left: 0;
  }

  #page-wrapper {
    width: 100%;
    padding: 0px;
    background-color: #fff;
    margin-top: 10px;
  }

  .display-print {
    display: contents;
  }

  .no-print {
    display: none;
  }

  .no-print-column {
    visibility: hidden;
  }

  // fix transfer table for printing
  .print-transfer-table {
    table {
      table-layout: fixed !important;
    }
    table .print-only {
      display: table-row !important;
    }
    table td {
      padding: 0px !important;
      border: 1px solid #DEE2E6;
    }
    table th {
      border-top: 1px solid white;
      padding: 0px !important;
      -webkit-box-shadow:inset 0px 0.5px 0px 1px #DEE2E6 !important;
      -moz-box-shadow:inset 0px 0.5px 0px 1px #DEE2E6 !important;
      box-shadow:inset 0px 0.5px 0px 1px #DEE2E6 !important;
    }
    table th:last-child::after {
      content: " ";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .print-transfer-table.seller {
    .spacer {
      line-height: 42px !important;
    }
    table {
      margin-left: -16px;
      width: 103% !important;
    }
    table th,
    table td {
      font-size: 12px;
      letter-spacing: -0.5px;
    }
    table th:nth-child(2),
    table th:nth-child(3),
    table th:nth-child(9),
    table th:nth-child(14) {
      font-size: 11px;
    }
    table th:nth-child(1),
    table th:nth-child(2),
    table th:nth-child(3),
    table th:nth-child(5) {
      min-width: 16mm !important;
      width: 16mm !important;
      max-width: 16mm !important;
    }
    table th:nth-child(4){
      width: 12mm !important;
    }
    table th:nth-child(7),
    table th:nth-child(12),
    table th:nth-child(20) {
      width: 20mm !important;
    }
    table th:nth-child(11),
    table th:nth-child(17) {
      width: 22mm !important;
    }
    table th:nth-child(6),
    table th:nth-child(8),
    table th:nth-child(19),
    table th:nth-child(21) {
      width: 26mm !important;
    }
    table th:nth-child(9),
    table th:nth-child(15) {
      width: 11mm !important;
    }
    table th:nth-child(14){
      width: 9mm !important;
    }
    table th:nth-child(10),
    table th:nth-child(13),
    table th:nth-child(16),
    table th:nth-child(18) {
      min-width: 24mm !important;
      width: 24mm !important;
      max-width: 24mm !important;
    }
  }
  
  .print-transfer-table.channel {
    .spacer {
      line-height: 40px !important;
    }
    table {
      margin-left: -22px;
      width: 104% !important;
    }
    table th,
    table td {
      font-size: 11px;
      letter-spacing: -0.5px;
    }
    table th:nth-child(2),
    table th:nth-child(3),
    table th:nth-child(14),
    table th:nth-child(18) {
      font-size: 10.5px;
    }
    table th:nth-child(1),
    table th:nth-child(2),
    table th:nth-child(3),
    table th:nth-child(5) {
      min-width: 16mm !important;
      width: 16mm !important;
      max-width: 17mm !important;
    }
    table th:nth-child(6),
    table th:nth-child(8),
    table th:nth-child(23) {
      min-width: 24mm !important;
      width: 24mm !important;
      max-width: 24mm !important;
    }
    table th:nth-child(7) {
      width: 18mm !important;
    }
    table th:nth-child(10),
    table th:nth-child(13),
    table th:nth-child(15),
    table th:nth-child(17),
    table th:nth-child(20), 
    table th:nth-child(22) {
      min-width: 22mm !important;
      width: 22mm !important;
      max-width: 22mm !important;
    }
    table th:nth-child(11),
    table th:nth-child(12),
    table th:nth-child(16),
    table th:nth-child(21) {
      min-width: 20mm !important;
      width: 20mm !important;
      max-width: 20mm !important;
    }
    table th:nth-child(14),
    table th:nth-child(18) {
      width: 8mm !important;
    }
    table th:nth-child(4){
      width: 11mm !important;
    }
    table th:nth-child(9),
    table th:nth-child(19) {
      min-width: 9mm !important;
      width: 9mm !important;
      max-width: 9mm !important;
    }
  }

  .print-pre-warp {
    white-space: pre-wrap !important
  }

  .print-col-4 {
    width: 33.33% !important;
    max-width: 33.33% !important;
  }
  .print-offset-4 {
    margin-left: 33.33% !important;
  }

  .print-border {
    border: 1px solid #ccc !important;
    border-radius: 16px;
  }

  // fix indent in transfer print.
  .p2,
  .card-body {
    padding-left: 0 !important;
  }

  // fix print whole page
  .content-container {
    height: 100%;
  }

  // remove padding left when printing
  .no-pl-print {
    padding-left: 0;
  }
}

// Utilities
.border-right-none {
  border-right: 0 !important;
}

.swal2-validation-message::before {
  content: '' !important;
  display: none !important;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  zoom: normal;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

.bg-base {
  background-color: #f4f3ed;
}

.label-header {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #161616;
}
.label-h5 {
  font-size: 1em;
  line-height: 1.3;
  font-weight: 700;
}
.box-payment-gateway-item {
  position: relative;
  display: inline-block;
  margin: 12px 10px;
  width: 80px;
  height: 80px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 2px solid #c6c6c6;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
  }
}
.box-payment-gateway-item img {
  display: block;
  margin: 0 auto;
  margin-top: inherit;
  pointer-events: none;
}
.box-payment-gateway-item.active {
  border: solid 2px #e20a1a;
}
.box-select-payment {
  margin-bottom: 1em;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: $white;
} /* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
  background-color: $light-silver;
  border-radius: 10px;
} /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  display: none;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  display: none;
}
$background-color-button: #e20a1a;
$background-color-hover-button: #af0815;
$color-white: #ffffff;
$color-black: #161616;
$color-border-solid: #8d8d8d;
$size-height-input: 48px;
.react-datepicker__input-container {
  .button-datepicker {
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    border-radius: 8px;
    border: solid 1px $color-border-solid !important;
    background-color: $color-white !important;
    color: $color-black !important;
    padding: 0 12px;
    .calendar-datepicker {
      float: right;
    }
  }
}
.react-datepicker {
  font-size: 16px;
  font-weight: normal;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $color-white;
  border: none;
  margin: 4px 0 0;
  padding: 14px;
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day--today--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: $background-color-button !important;
    border-radius: 20px;
    &:hover {
      background-color: $background-color-hover-button;
    }
    &:focus {
      outline: none;
    }
  }
  .react-datepicker__day--today {
    border-radius: 20px;
    border: solid 1px #e20a1a;
  }
  .react-datepicker__time-list-item--selected {
    background-color: $background-color-button !important;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: $color-white;
    border-bottom: none;
  }
  .react-datepicker__day {
    &:hover {
      border-radius: 20px;
    }
  }
  .react-datepicker__month-container {
    background-color: $color-white;
    .react-datepicker__current-month {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: $color-black;
    }
    .react-datepicker__day-name {
      line-height: 1.81;
      color: $color-black;
      margin: 3px;
    }
    .react-datepicker__day {
      margin: 3px;
    }
  }
  .react-datepicker__navigation {
    outline: none;
  }
}
.react-datepicker__input-container {
  .nexter-input {
    display: inline;
    width: 150px;
    border: none;
    height: auto;
    padding: 0;
    background-color: transparent;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  .icon-calendar {
    display: inline-flex;
  }
}
.text-upper {
  text-transform: uppercase;
}
.p0 {
  padding: 0 !important;
}
.swal2-styled {
  padding: inherit !important;
}
.forgot-password {
  display: flex;
  justify-content: flex-end;
}
.center-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-flow: column;
}
.card-shadow0 {
  border-radius: 16px;
  box-shadow: 0 5px 12px 0 rgba(17, 17, 17, 0.16);
}
.black-font {
  color: $black;
}
.display-content {
  display: contents !important;
}
.gray-font {
  color: $opacity-gray-8;
}
.card-top-no-radius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card-bottom-no-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-panel {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px;
}

//Pagination
.page-link {
  font-size: 13px;
}

//Go Back
.title-goback {
  font-size: 20px;
  padding-top: 8px;
  margin-bottom: 30px;
  .back-icon {
    color: $SCG-red;
  }
  .texthead-goback {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: $black;
  }
}

.content {
  position: relative;
}

.custom-radio {
  .custom-control-label {
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-color: $gray;
      border-width: 2px;
      background-color: $white;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $SCG-red;
    background-color: $white;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23e20a1a' /%3E%3C/svg%3E");
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba($SCG-red, 0.25);
  }
}